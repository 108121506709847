import axios from "axios";
import { base_url } from "../../utils/api";

const register = async (userData) => {
    const response = await axios.post(`${base_url}user/register`, userData);
    console.log('API response:', response);
    if (response.data) {
        return response.data;
    }
};

// Store the token properly during login
const login = async (userData) => {
    const response = await axios.post(`${base_url}user/login`, userData);

    if (response.data.token) {
        localStorage.setItem("token", response.data.token); // Store token with the correct key
    }
    return response.data;
};

// Retrieve the token properly and use it for the getUser function
const getUser = async () => {
    const token = localStorage.getItem("token"); // Retrieve the token

    if (!token) {
        throw new Error('No token found');
    }

    const response = await axios.get(`${base_url}user`, {
        headers: {
            Authorization: `Bearer ${token}`, // Send token in Authorization header
        },
    });

    return response;
};



export const authService = {

    register,
    login,
    getUser
};