//import React from 'react';
import './CardLoader.css'; // Import the CSS file

const CardLoader = () => {
    return (
        <div className='flex -mt-20 items-center justify-center min-h-screen' >
            <div className="loader"></div>
        </div>

    );
};

export default CardLoader;
