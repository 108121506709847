import React, { lazy, Suspense, useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
//import Header from "./Components/Header";
import CardLoader from "./Order/CardLoader"; // Import your loader component

// Lazy load components
const Dashboard = lazy(() => import("./Dashboard/Dashboard"));
const UserPhoto = lazy(() => import("./Dashboard/AddTest"));
const OrderDetail = lazy(() => import("./Order/OrderDetail"));
const Orders = lazy(() => import("./Order/Orders"));
const Signin = lazy(() => import("./Component/Signin"));
const Signup = lazy(() => import("./Component/Signup"));
const ForgotPassword = lazy(() => import("./Component/ForgotPassword"));
const VerifyOtp = lazy(() => import("./Component/VerifyOtp"));
const ResetPassword = lazy(() => import("./Component/ResetPassword"));
const AdminFundRequests = lazy(() => import("./Order/AdminFundRequests"));
const UserList = lazy(() => import("./User/UserList"));
const UserDetail = lazy(() => import("./User/UserDetail"));
const MassEmail = lazy(() => import("./Dashboard/MassEmail"));
const ProtectedRoute = lazy(() => import("./routes/AdminRoute"));
const ReferredUsers = lazy(() => import("./User/ReferredUsers"));
const SingleUserEmail = lazy(() => import("./Dashboard/SingleUserEmail"));

const App = () => {
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 500); // Loader displays for 500ms

    return () => clearTimeout(timer);
  }, []);
  //jhchghcjysksinja
  return (
    <Router>
      <div className="app-container">
        {showLoader && <CardLoader />} {/* Show the CardLoader while loading */}
        {!showLoader && (
          <>

            <main>
              <Suspense fallback={<CardLoader />}>
                <Routes>
                  <Route path="/" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
                  <Route path="/user-photo-requirement" element={<ProtectedRoute><UserPhoto /></ProtectedRoute>} />
                  <Route path="/admin/forgot-password" element={<ForgotPassword />} />
                  <Route path="/admin/verify-otp" element={<VerifyOtp />} />
                  <Route path="/admin/reset-password" element={<ResetPassword />} />
                  <Route path="/admin/sign-in" element={<Signin />} />
                  <Route path="/admin/sign-up" element={<Signup />} />
                  <Route path="/order-detail/:id" element={<ProtectedRoute><OrderDetail /></ProtectedRoute>} />
                  <Route path="/order" element={<ProtectedRoute><Orders /></ProtectedRoute>} />
                  <Route path="/user/user-list" element={<ProtectedRoute><UserList /></ProtectedRoute>} />
                  <Route path="/user/user-list/user-detail/:orderId" element={<ProtectedRoute><UserDetail /></ProtectedRoute>} />
                  <Route path="/user/fund-request" element={<ProtectedRoute><AdminFundRequests /></ProtectedRoute>} />
                  <Route path="/admin/mass-email" element={<ProtectedRoute><MassEmail /></ProtectedRoute>} />
                  <Route path="/admin/single-user-email" element={<ProtectedRoute><SingleUserEmail /></ProtectedRoute>} />
                  <Route path="/user/referal" element={<ProtectedRoute><ReferredUsers /></ProtectedRoute>} />
                </Routes>
              </Suspense>
            </main>
          </>
        )}
      </div>
    </Router>
  );
};

export default App;
